const Loading = () => {
    return (
        <div id="about" className="container-fluid">
            <div className="row">
                <div className="col-12 p-0">
                    <div
                        className="card jumbotron vh-100 text-center m-0 bg-opacity-10 sharp-corners d-flex flex-column justify-content-center">
                        <div style={{position: "absolute", top: "30vh", width: "100%"}}>
                            <div className="spinner-border" style={{width: "4rem", height:"4rem"}} role="status">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loading
