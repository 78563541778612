import profile from "../elements/images/misc/smallProfile.png";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {
    FaCommentAlt,
    FaEnvelope,
    FaLinkedin,
    FaPaperPlane
} from "react-icons/fa";

const Notifications = () => {
    const [chat, setChat] = useState([
        {role: "time", message: new Date().toLocaleString()},
        {role: "lewis gibney", message: "Welcome to my website! How can I be of assistance?"},
    ]);

    const location = useLocation();

    const sendRequest = () => {
        if (document.getElementById("AI-btn").disabled === true) {
            return;
        }

        const input = document.getElementById("AI-input").value;

        //Add the user's message to the chat
        let newChat = [...chat, {role: "user", message: input}];
        setChat(newChat);

        //Check if the input is empty
        if (input === "") {
            //Add a random message about sending a blank message to the chat
            let messageOptions = [
                "Why would you send a blank message?",
                "Wow a blank message, how original.",
                "A blank message, you must be really fun at parties.",
                "I'm not going to respond to a blank message.",
            ];
            let random = Math.floor(Math.random() * messageOptions.length);

            //Add the message to the chat
            setChat(prevChat => [...prevChat, {role: "alt", message: messageOptions[random]}]);

            return;
        }

        //Reset the input, disable and change its placeholder text to say 'sending...'
        document.getElementById("AI-input").value = "";
        document.getElementById("AI-btn").disabled = true;
        document.getElementById("AI-input").placeholder = "Sending...";

        //Disable the chat button
        document.getElementById("AI-btn").disabled = true;

        //Send the request to the server
        axios.post('https://lewisgibney.tech/ai-assistant-api/generateWebResponse', {
            chat: newChat.filter(message => message.role !== "error")
        }).then(data => {
            //Add the AI's response to the chat
            setChat(prevChat => [...prevChat, {role: "lewis gibney", message: data.data.result}]);

            //Re-enable the input and chat button
            document.getElementById("AI-input").placeholder = "Write your message here...";
            document.getElementById("AI-btn").disabled = false;
        }).catch(err => {
            //Add error message to the chat
            setChat(prevChat => [...prevChat, {role: "error", message: "Issue getting a response from the AI :/"}]);

            //Change the placeholder text to say 'Try again later' and re-enable the chat button
            document.getElementById("AI-input").placeholder = "Try again later...";
            document.getElementById("AI-btn").disabled = false;
        });
    };

    const showToast = () => {
        document.getElementById("AI-toast").classList.remove("hide");
        document.getElementById("AI-toast").classList.add("show");
    };

    const hideToast = () => {
        document.getElementById("AI-toast").classList.remove("show");
        document.getElementById("AI-toast").classList.add("hide");
    };

    const showButton = () => {
        document.getElementById("showButtons").classList.remove("invisible");
        document.getElementById("showButtons").classList.add("visible");
    };

    const hideButton = () => {
        document.getElementById("showButtons").classList.remove("visible");
        document.getElementById("showButtons").classList.add("invisible");
    };

    useEffect(() => {
        //scroll to the bottom of the chat
        const chatBody = document.querySelector(".toast-body");
        chatBody.scrollTop = chatBody.scrollHeight;
    }, [chat]);

    useEffect(() => {
        //decide if we should hide the toast
        if (location.pathname.includes("vr") || location.pathname.includes("AI-LG")) {
            hideToast()
            hideButton()
        }else{
            showButton()
        }
    }, [location]);

    useEffect(() => {

        //decide if we should show the toast - not VR or AI-LG and not mobile
        // if (!location.pathname.includes("vr") && !location.pathname.includes("AI-LG") && window.innerWidth > 768){
        //     //Show the toast
        //     showToast()
        // }
    }, []);

    return (
        <>
            <div id="showButtons" className="">
            {/*    <div className="position-fixed bottom-0 end-0 p-3">*/}
            {/*        <button className="show btn btn-primary" style={{*/}
            {/*            width: "70px",*/}
            {/*            height: "70px",*/}
            {/*            borderRadius: "35px",*/}
            {/*            fontSize: "12px",*/}
            {/*            textAlign: "center"*/}
            {/*        }}*/}
            {/*                onClick={showToast}>*/}
            {/*            <FaCommentAlt style={{fontSize: "xx-large"}}/>*/}
            {/*        </button>*/}
            {/*    </div>*/}

                <div className="position-fixed bottom-0 start-0 p-3">
                    <div className="container-fluid">
                        <div className="row">
                            {/*LinkedIn Button*/}
                            <button className="show btn btn-primary" style={{
                                width: "70px",
                                height: "70px",
                                borderRadius: "35px",
                                fontSize: "12px",
                                textAlign: "center"
                            }}
                                    onClick={() => window.open('https://www.linkedin.com/in/lewis-gibney/', '_blank')}>
                                <FaLinkedin style={{fontSize: "xx-large"}} />
                            </button>
                        </div>

                        <div className="row mt-2">
                            {/*Email Button*/}
                            <button className="show btn btn-primary" style={{
                                width: "70px",
                                height: "70px",
                                borderRadius: "35px",
                                fontSize: "12px",
                                textAlign: "center"
                            }}
                                    onClick={() => window.open('mailto:lewisgibney14@gmail.com', '_blank')}>
                                <FaEnvelope style={{fontSize: "xx-large"}} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        <div className="position-fixed bottom-0 end-0 p-3">
            <div id="AI-toast" className="toast hidden">
                <div className="toast-header">
                    <img src={profile} style={{height:"2.5rem"}}  alt="profile image"/>
                    <strong className="me-auto"> Lewis Gibney</strong>
                    <small className="text-muted">AI-Assistant</small>
                    <button type="button" className="btn-close" data-bs-dismiss="toast"></button>
                </div>

                <div className="toast-body overflow-scroll" style={{maxHeight:"25vh"}}>
                    <div className="d-flex flex-column">

                        {chat.map((message, index) => {
                            if (message.role === "user") {
                                return (
                                    <div className="justify-content-end" key={index}>
                                        <p className="col-9 msg right">{message.message}</p>
                                    </div>
                                )
                            } else if (message.role === "lewis gibney" || message.role === "alt") {
                                return (
                                    <div className="justify-content-start" key={index}>
                                        <p className="col-9 msg left">{message.message}</p>
                                    </div>
                                )
                            } else if (message.role === "error") {
                                return (
                                    <p className="text-danger text-center" key={index}>{message.message}</p>
                                )
                            }
                        })}
                    </div>
                </div>

                <div className="toast-footer">
                    <div className="input-group">
                        <input autoComplete={false} id="AI-input" type="text" className="form-control input-sm chat_input"
                               placeholder="Write your message here..." onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                sendRequest();
                            }
                        }}/>
                        <span className="input-group-btn">
                            <button id="AI-btn" className="btn btn-primary disable" onClick={sendRequest}>
                                <FaPaperPlane/>
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
};



export default Notifications;
