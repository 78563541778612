import {NavLink, useLocation} from "react-router-dom";
import {useEffect} from "react";

setInterval(function () {
    if (document.getElementById("title").innerHTML === "lewisgibney.tech_") {
        document.getElementById("title").innerHTML = "lewisgibney.tech";
    } else {
        document.getElementById("title").innerHTML = "lewisgibney.tech_";
    }
}, 1000)

const Navbar = () => {
    const location = useLocation();

    const pageCheck = () => {
        let portButton = document.getElementById("portfolio-btn");

        if(location.pathname.includes("portfolio")){
            setTimeout(() => {
                portButton.className = "nav-link dropdown-toggle active";
            },100)

        }else{
            setTimeout(() => {
                portButton.className = "nav-link dropdown-toggle";
            },100)
        }
    }

    useEffect(pageCheck, [location]);

    const clickNav = () => {
        window.scrollTo(0,0);
    }

    return(
        <header id="header" className="container-fluid p-0 sticky-top">
            <div className="d-flex flex-column flex-md-row align-items-center">
                <h1 id="title" className="d-flex pt-3 pb-1 pt-sm-3 p-md-3 align-items-center">lewisgibney.tech</h1>
                <nav className="d-inline-flex ms-md-auto">
                    <ul className="nav nav-pills mt-2 mx-2">
                        <li className="nav-item">
                            <NavLink id="about-btn" to="about" onClick={clickNav} className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>About</NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink id="vr-btn" to="vr" onClick={clickNav} className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>E-Office</NavLink>
                        </li>

                        <li className="nav-item dropdown">
                            <p id="portfolio-btn" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" data-bs-target="portfolio-btn">Portfolio</p>
                            <ul className="dropdown-menu">
                                <li><NavLink to="portfolio" onClick={clickNav}
                                             className={({isActive}) => isActive ? "nav-link active" : "nav-link"}
                                             end>All</NavLink></li>
                                <li>
                                    <hr className="dropdown-divider"/>
                                </li>
                                <li><NavLink to="portfolio/computing" onClick={clickNav}
                                             className={({isActive}) => isActive ? "nav-link active" : "nav-link"}
                                             end>Computing</NavLink></li>
                                <li><NavLink to="portfolio/app_development" onClick={clickNav}
                                             className={({isActive}) => isActive ? "nav-link active" : "nav-link"}
                                             end>Apps</NavLink></li>
                                <li><NavLink to="portfolio/engineering" onClick={clickNav}
                                             className={({isActive}) => isActive ? "nav-link active" : "nav-link"}
                                             end>Engineering</NavLink></li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Navbar;
