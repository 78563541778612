import './App.css';
import Navbar from './Navigation/Navbar';
import {Routes, Route, Navigate} from 'react-router-dom';
import React, { Suspense } from 'react';
import PortfolioData from "./elements/portfolio-data.json";
import Notifications from "./Navigation/Notifications";
import Loading from "./Pages/Loading";


const About = React.lazy(() => import('./Pages/About'));
const FullPortfolio = React.lazy(() => import('./Pages/PortfolioComponents/FullPortfolio'));
const Footer = React.lazy(() => import('./Navigation/Footer'));
const Admin = React.lazy(() => import('./Pages/Admin'));
const AppPortfolio = React.lazy(() => import('./Pages/PortfolioComponents/AppPortfolio'));
const ComputingPortfolio = React.lazy(() => import('./Pages/PortfolioComponents/ComputingPortfolio'));
const EngineeringPortfolio = React.lazy(() => import('./Pages/PortfolioComponents/EngineeringPortfolio'));
const EOffice = React.lazy(() => import('./Pages/EOffice'));
const AIchat = React.lazy(() => import('./Pages/PortfolioComponents/Prototypes/AIChat'));


function App() {

    let camo1 = null;
    let camo2 = null;

    window.onload = function () {
        camo1 = document.getElementById("camoL1");
        camo2 = document.getElementById("camoL3");
    }

    //on page scroll, move background images
    window.onscroll = function() {
        let amount = 0;

        //Get Position
        if (window.pageXOffset)// Chrome
            amount = window.pageXOffset;
        else if (document.documentElement.clientHeight)// ie
            amount = document.documentElement.scrollTop;
        else if (document.body)// other
            amount = document.body.scrollTop;

        camo1.style.backgroundPositionX = amount + 'px';
        camo2.style.backgroundPositionX = -amount + 'px';
    };

  return (
      <div id="page-container">
          {/*Background Elements*/}
          <div id="camoL1" class="camo">
          </div>
          <div id="camoL3" class="camo">
          </div>

          {/*Navbar*/}
          <Navbar/>

          {/*Main Content*/}
          <div id="content-wrap">
              <Suspense fallback={<Loading />}>
                  <Routes>
                      <Route exact path="about" element={<About />} />
                      <Route exact path="portfolio" element={<FullPortfolio data={PortfolioData}/>} />
                      <Route exact path="portfolio/app_development" element={<AppPortfolio data={PortfolioData}/>} />
                      <Route exact path="portfolio/computing" element={<ComputingPortfolio data={PortfolioData}/>} />
                      <Route exact path="portfolio/engineering" element={<EngineeringPortfolio data={PortfolioData}/>} />

                      {/*TODO: Create prototype landing page under portfolio*/}
                      {/*<Route exact path="portfolio/prototypes" element={} />*/}

                      {/*TODO: Create an admin page*/}

                      <Route exact path="portfolio/prototypes/AI-LG" element={<AIchat />} />
                      <Route exact path="admin" element={<Admin />} />
                      <Route exact path="vr" element={<EOffice data={PortfolioData}/>}/>
                      <Route
                          path="*"
                          element={<Navigate to="about" replace />}
                      />
                  </Routes>
              </Suspense>
          </div>

          {/*Footer*/}
          <Footer />

          {/*Notifications*/}
          <Notifications />

      </div>
  );
}

export default App;
